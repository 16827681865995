const initialValues = {
  status: null,
  error: null,
  type: null,
  activeTab: 0,
  recoId: null,
  statementId: null,
  partyIds: [],
  companyStatementData: [],
  configureData: [],
  modalData: [],
  sendModalData: [],
  companyData: [],
  requestType: [],
  statementType: [],
  branchType: [],
  contactPersonType: [],
  emailTemplate: [],
  reminderTemplate: [],
  emailAttachment: [],
  senderEmail: [],
  uploadFile: [],
  dateFormats: [],
  validData: [],
  selected: [],
  configData: {},
  openGood: false,
  sort: { id: "", direction: "ascending" },
  isAllItemSelected: false,
  searchText: "",
  configureInfo: {},
  existingRecoId: "",
  shareOption: "",
  companyStatementGRIR: [],
  statementIdGrir: "",
};

export default initialValues;
