import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosApi from "../../helper/axiosApi";
import state from "./state";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

// Get Main- Statement Table Data
export const getTableRecoData = createAsyncThunk(
  "getTableRecoData",
  async ({ searchString, page, limit, sortBy }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/bulkreco/listing?pageSize=${limit}&pageNo=${page}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Search Option
export const getSearchData = createAsyncThunk(
  "getSearchData",
  async ({ searchString, limit, page }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/bulkreco/search?pageSize=${limit}&pageNo=${page}&searchString=${searchString}`,
        {}
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Statistics component API
export const getStatisticsData = createAsyncThunk(
  "getStatisticsData",
  async (recoId, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/statistics?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get All Parties Data
export const getAllPartiesTableData = createAsyncThunk(
  "getAllPartiesTableData",
  async (
    { page, limit, sortBy, recoId, isPriority, searchString },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/allparty?recoId=${recoId}&pageSize=${limit}&pageNo=${page}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&isPriority=${isPriority}&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Reconciliation pending(RecoPending) Table Data
export const getReconciliationTableData = createAsyncThunk(
  "getReconciliationTableData",
  async (
    { page, limit, sortBy, recoId, isPriority, searchString },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/pending-reco?recoId=${recoId}&pageSize=${limit}&pageNo=${page}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&isPriority=${isPriority}&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Review pending Table Data
export const getReviewPendingTableData = createAsyncThunk(
  "getReviewPendingTableData",
  async (
    { page, limit, sortBy, recoId, isPriority, searchString },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/pending-review?recoId=${recoId}&pageSize=${limit}&pageNo=${page}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&isPriority=${isPriority}&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Sign Off Table Data
export const getSignOffTableData = createAsyncThunk(
  "getSignOffTableData",
  async (
    { page, limit, sortBy, recoId, isPriority, searchString },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/party-signoff?recoId=${recoId}&pageSize=${limit}&pageNo=${page}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&isPriority=${isPriority}&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Action Tracker Table Data
export const getActionTrackerTableData = createAsyncThunk(
  "getActionTrackerTableData",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/actiontracker/summary?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download summary
export const getdownloadSummary = createAsyncThunk(
  "getdownloadSummary",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      axiosApi
        .post(
          `/recon/api/v1/report/summary-report`,
          {
            recoId,
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `summaryrecoreport.xlsx`);
          // `${partyId}_recoreport.xlsx`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const getdownloadReport = createAsyncThunk(
  "getdownloadReport",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      axiosApi
        .post(
          `/recon/api/v1/report/detail-report`,
          {
            recoId,
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `recoreport.xlsx`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Close Request
export const closeRequest = createAsyncThunk(
  "closeRequest",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        // PUT http://{{awshost}}/reconinit/api/v1/recorecords/tracking/close-reco?recoId=63bd8758ae9b81291688ec75
        // `/reconinit/api/v1/recorecords/${recoId}/close-reco`,
        `/reconinit/api/v1/recorecords/tracking/close-reco?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Reopen Request
export const reOpenRequest = createAsyncThunk(
  "reOpenRequest",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        // PUT http://{{awshost}}/reconinit/api/v1/recorecords/tracking/open-reco?recoId=63bd8758ae9b81291688ec75
        // `/reconinit/api/v1/recorecords/${recoId}/open-reco`,
        `/reconinit/api/v1/recorecords/tracking/open-reco?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Start Reminder
export const startReminder = createAsyncThunk(
  "startReminder",
  async ({ recoId, isAllSelected, partyIds }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/start-reminder?recoId=${recoId}&isSelectAll=${isAllSelected}`,
        { partyIds }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Stop Reminder
export const stopReminder = createAsyncThunk(
  "stopReminder",
  async ({ recoId, isAllSelected, partyIds }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/stop-reminder?recoId=${recoId}&isSelectAll=${isAllSelected}`,
        { partyIds }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// contact person,Email Attachment,Reminder Template Dropdown List API
export const getContactPersonType = createAsyncThunk(
  "getContactPersonType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/company/api/v1/companyusers`);
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// contact Email Attachment Type
export const getEmailAttachment = createAsyncThunk(
  "getEmailAttachment",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/company/api/v1/companies/email-attachment`
      );
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// contact Reminder Template Type
export const getReminderTemplate = createAsyncThunk(
  "getReminderTemplate",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/company/api/v1/companies/reminder-templates`
      );
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//submit button API call-Modify Configuration
export const sendModifyConfigurationData = createAsyncThunk(
  "sendModifyConfigurationData",
  async ({ recoId, modifyConfigData }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v2/recorecords/${recoId}/reco-config`,
        modifyConfigData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Mark as Review Done
export const markAsReviewDone = createAsyncThunk(
  "markAsReviewDone",
  async ({ recoId, partyIds, comment, isAllSelected }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/review-done`,
        { partyIds, recoId, isSelectAll: isAllSelected, comment }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Attach SignOff All Parties
export const attachSignOff = createAsyncThunk(
  "attachSignOff",
  async ({ recoId, partyId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/attach-signoff?recoId=${recoId}&partyId=${partyId}`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get file needed
export const getFileNeeded = createAsyncThunk(
  "getFileNeeded",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconinit/api/v1/tracking/files-needed`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Emails
export const getEmails = createAsyncThunk(
  "getEmails",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/tracking/email-contacts?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Request SignOff
export const requestSignOff = createAsyncThunk(
  "requestSignOff",
  async ({ recoId, partyIds, comment, isAllSelected }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/request-signoff`,
        { partyIds, recoId, isSelectAll: isAllSelected, comment }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Mark as High Priority
export const markAsHighPriority = createAsyncThunk(
  "markAsHighPriority",
  async ({ recoId, partyIds, isAllSelected }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/mark-priority?recoId=${recoId}&isSelectAll=${isAllSelected}`,
        { partyIds }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Mark as Unmark/Remove Priority
export const markAsRemovePriority = createAsyncThunk(
  "markAsRemovePriority",
  async ({ recoId, partyIds, isAllSelected }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/unmark-priority?recoId=${recoId}&isSelectAll=${isAllSelected}`,
        { partyIds }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Reject Reco
export const rejectReco = createAsyncThunk(
  "rejectReco",
  async ({ recoId, partyIds, comment, isAllSelected }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/reject-reco`,
        { partyIds: [partyIds], recoId, isSelectAll: false, comment }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Reject Statement
export const rejectStatement = createAsyncThunk(
  "rejectStatement",
  async ({ recoId, partyId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/reject-statement?recoId=${recoId}&partyId=${partyId}`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Send for review
export const sendForReview = createAsyncThunk(
  "sendForReview",
  async ({ recoId, partyIds, isAllSelected, comment }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/send-for-review`,
        { partyIds, recoId, isSelectAll: isAllSelected, comment }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Send comment
export const sendComment = createAsyncThunk(
  "sendComment",
  async ({ recoId, partyId, comment, commentType }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/add-comment`,
        { recoId, partyId, comment, commentType }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Reminder Template Type- Send Reminder Dropdown
export const getReminderTemplateTypes = createAsyncThunk(
  "getReminderTemplateTypes",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/tracking/reminder-templates`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Template Content Type- Send Reminder Dropdown
export const getTemplateContentsTypes = createAsyncThunk(
  "getTemplateContentsTypes",
  async (templateGroupID, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/tracking/template-contents?templateGroupId=${templateGroupID}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Send Reminder popup
export const sendReminder = createAsyncThunk(
  "sendReminder",
  async (
    { recoId, selectedId, partyIds, email_template_id, isAllSelected },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/send-reminder?emailTemplateId=${email_template_id}&recoId=${recoId}&isSelectAll=${isAllSelected}`,
        { partyIds }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Status Actions
export const statusActions = createAsyncThunk(
  "statusActions",
  async (action, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/statusactions?action=${action}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Party Contacts
export const partyContacts = createAsyncThunk(
  "partyContacts",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/tracking/party-contacts?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Add Comment
export const addComment = createAsyncThunk(
  "addComment",
  async ({ recoId, partyId, comment, commentType }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/add-comment`,
        { recoId, partyId, comment, commentType }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//update contacts
export const updateContacts = createAsyncThunk(
  "updateContacts",
  async ({ recoId, partyId, contacts }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/update-contact?recoId=${recoId}&partyId=${partyId}`,
        { contacts }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get All Comments
export const getAllComments = createAsyncThunk(
  "getAllComments",
  async ({ recoId, partyId, pageNo }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/all-comments?recoId=${recoId}&partyId=${partyId}&pageNo=${pageNo}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Reco Summary
export const getRecoSummary = createAsyncThunk(
  "getRecoSummary",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/summary?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Unmatched Data
export const getUnMatchedData = createAsyncThunk(
  "getUnMatchedData",
  async (
    {
      recoId,
      partyId,
      code,
      source1Val,
      source2Val,
      page,
      limit,
      sortBy,
      searchString,
    },
    { rejectWithValue }
  ) => {
    try {
      if (searchString === undefined) {
        searchString = "";
      }
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/by-code?recoId=${recoId}&partyId=${partyId}&code=${code}&pageNo=${page}&pageSize=${limit}&source1=${source1Val}&source2=${source2Val}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get source 1 dropdown list-company data set
export const getSourceOneList = createAsyncThunk(
  "getSourceOneList",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/masterdata/company-dataset?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get source 2 dropdown list-party data set
export const getSourceTwoList = createAsyncThunk(
  "getSourceTwoList",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/masterdata/party-dataset?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get status dropdown list
export const getStatusUnmatchedList = createAsyncThunk(
  "getStatusUnmatchedList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/masterdata/statuslist`
        // `/recon/api/v1/recorecords/masterdata/statuslist?code=${code}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Link Records modal-for matchedData screen
export const linkRecords = createAsyncThunk(
  "linkRecords",
  async (
    {
      recoId,
      partyId,
      statusCode,
      companyDataIds,
      partyDataIds,
      selectAll,
      searchString,
      statusCodes,
      code,
      source1Val,
      source2Val,
      uncheckedDataIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(`/recon/api/v1/recorecords/link-records`, {
        recoId,
        partyId,
        statusCode,
        companyDataIds,
        partyDataIds,
        selectAll,
        searchString,
        statusCodes,
        code,
        source1: source1Val,
        source2: source2Val,
        uncheckedDataIds,
      });
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//De-Link Records- for UnmatchedData screen
export const deLinkRecords = createAsyncThunk(
  "deLinkRecords",
  async (
    {
      recoId,
      partyId,
      companyDataIds,
      isAllSelected,
      searchString,
      code,
      statusCodes,
      source1,
      source2,
      uncheckedDataIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/recorecords/delink-records`,
        {
          recoId,
          partyId,
          companyDataIds,
          selectAll: isAllSelected,
          searchString,
          code,
          statusCodes,
          source1,
          source2,
          uncheckedDataIds,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Add remark modal-for unmatched/matched data screen
export const addRemark = createAsyncThunk(
  "addRemark",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/recorecords/add-remark`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Update Status modal-for unmatched/matched data screen
export const updateStatus = createAsyncThunk(
  "updateStatus",
  async (
    {
      recoId,
      partyId,
      comment,
      statusCode,
      companyDataIds,
      partyDataIds,
      selectAll,
      searchString,
      statusCodes,
      code,
      source1Val,
      source2Val,
      uncheckedDataIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/recorecords/update-status`,
        {
          recoId,
          partyId,
          statusCode,
          comment,
          companyDataIds,
          partyDataIds,
          selectAll,
          searchString,
          statusCodes,
          code,
          source1: source1Val,
          source2: source2Val,
          uncheckedDataIds,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get Reconcilation summary Query details
export const getQueryDetails = createAsyncThunk(
  "getQueryDetails",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/company-query?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Respond to  Query
export const respondQuery = createAsyncThunk(
  "respondQuery",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/query-response`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Accept Recommendation
export const acceptRecommendation = createAsyncThunk(
  "acceptRecommendation",
  async (
    {
      recoId,
      partyId,
      comment,
      companyDataIds,
      selectAll,
      searchString,
      statusCodes,
      code,
      source1Val,
      source2Val,
      uncheckedDataIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/recorecords/accept-recommendation`,
        {
          recoId,
          partyId,
          comment,
          companyDataIds,
          selectAll,
          searchString,
          statusCodes,
          code,
          source1: source1Val,
          source2: source2Val,
          uncheckedDataIds,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Reject Recommendation
export const rejectRecommendation = createAsyncThunk(
  "rejectRecommendation",
  async (
    {
      recoId,
      partyId,
      comment,
      companyDataIds,
      selectAll,
      searchString,
      statusCodes,
      code,
      source1Val,
      source2Val,
      uncheckedDataIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/recorecords/reject-recommendation`,
        {
          recoId,
          partyId,
          comment,
          companyDataIds,
          selectAll,
          searchString,
          statusCodes,
          code,
          source1: source1Val,
          source2: source2Val,
          uncheckedDataIds,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Action Taken modal- Dropdown List of Action Taken
export const getActionTakenList = createAsyncThunk(
  "getActionTakenList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/masterdata/actionlist`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Action Taken modal
export const updateActionTaken = createAsyncThunk(
  "updateActionTaken",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/actiontracker/action-taken`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Assign/Update Action Owner modal-For Matched Unmatched Screen
export const updateActionOwner = createAsyncThunk(
  "updateActionOwner",
  async (
    {
      comment,
      actionOwnerName,
      actionOwnerEmail,
      actionOwnerId,
      dataIds,
      selectAll,
      searchString,
      statusCodes,
      code,
      source1Val,
      source2Val,
      recoId,
      partyId,
      uncheckedDataIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/actiontracker/action-owner`,
        {
          comment,
          actionOwnerName,
          actionOwnerEmail,
          actionOwnerId,
          dataIds,
          selectAll,
          searchString,
          statusCodes,
          code,
          source1Val,
          source2Val,
          recoId,
          partyId,
          uncheckedDataIds,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Get Confirmation token for Mark As Respond Action
export const getRespondToken = createAsyncThunk(
  "getRespondToken",
  async ({ reconId, partyId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("tempToken");
      const jwtToken = token.split(" ")[1];
      const res = await axiosApi.post(
        `/token/api/v1/party/confirmation-token`,
        { reconId, partyId, jwtToken }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Status List- Dropdown List of Update Status Modal(for unmatched/matched screen)
export const getUpdateStatusList = createAsyncThunk(
  "getUpdateStatusList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/masterdata/update-statuslist`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Status List- Dropdown List of Link Modal(Only for Unmatched Screen)
export const getLinkList = createAsyncThunk(
  "getLinkList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/masterdata/link-statuslist`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// View Remarks- List of remarks on Add Remark Modal(Unmatched/matched Screen)
export const getViewRemarkData = createAsyncThunk(
  "getViewRemarkData",
  async ({ recoId, partyId, dataId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/get-remarks?recoId=${recoId}&partyId=${partyId}&dataId=${dataId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get Count for Tabs-Table Record Count
export const getCountOfTableRecords = createAsyncThunk(
  "getCountOfTableRecords",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/counts?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadDocument = createAsyncThunk(
  "downloadDocument",
  async ({ recoId, partyId, doc }, { rejectWithValue }) => {
    try {
      axiosApi
        .get(
          `/reconinit/api/v1/recorecords/tracking/company/party-document?recoId=${recoId}&partyId=${partyId}&documentName=${doc}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${doc}`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//status change of unmatched screen
// Get Unmatched Data
export const getUpdatedStatusList = createAsyncThunk(
  "getUpdatedStatusList",
  async (
    {
      recoId,
      partyId,
      pageNo,
      pageSize,
      sortBy,
      sortDirection,
      source1Val,
      source2Val,
      searchString,
      code,
      statusCodes,
    },
    { rejectWithValue }
  ) => {
    try {
      if (searchString === undefined) {
        searchString = "";
      }
      const res = await axiosApi.post(
        `/recon/api/v1/recorecords/filter/by-statuscodes`,
        {
          recoId,
          partyId,
          pageNo,
          pageSize,
          sortBy,
          sortDirection,
          source1Val,
          source2Val,
          searchString,
          code,
          statusCodes,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get matched Data
export const getMatchedList = createAsyncThunk(
  "getMatchedList",
  async (
    {
      recoId,
      partyId,
      pageNo,
      pageSize,
      sortBy,
      sortDirection,
      source1Val,
      source2Val,
      searchString,
      code,
      statusCodes,
    },
    { rejectWithValue }
  ) => {
    try {
      if (searchString === undefined) {
        searchString = "";
      }
      const res = await axiosApi.post(
        `/recon/api/v1/recorecords/filter/by-statuscodes`,
        {
          recoId,
          partyId,
          pageNo,
          pageSize,
          sortBy,
          sortDirection,
          source1Val,
          source2Val,
          searchString,
          code,
          statusCodes,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Reco Record By Id
export const getRecoRecordById = createAsyncThunk(
  "getRecoRecordById",
  async (recoId, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconinit/api/v1/recorecords/${recoId}`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Get records for action tracker view screen-View click
export const getRecordsToViewActionTrackerRecords = createAsyncThunk(
  "getRecordsToViewActionTrackerRecords",
  async (
    {
      recoId,
      actionStatus,
      searchString,
      actionStatusList,
      recoStatusList,
      actionOwnerList,
      pageNo,
      pageSize,
      sortBy,
      sortDirection,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.post(
        `/recon/api/v1/actiontracker/records/all`,
        {
          recoId,
          actionStatus,
          searchString,
          actionStatusList,
          recoStatusList,
          actionOwnerList,
          pageNo,
          pageSize,
          sortBy,
          sortDirection,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get action owner multiselect dropdown list-Action Tracker View Screen
export const getActionTrackerActionOwnerStatusList = createAsyncThunk(
  "getActionTrackerActionOwnerStatusList",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/masterdata/action-owner-list?recoId=${recoId}`
      );
      return res;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Reconciliation status filter-Action View Tracker Screen
export const getActionTrackerReconciliationStatusFilterList = createAsyncThunk(
  "getActionTrackerReconciliationStatusFilterList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/masterdata/pendingreco-status-list`
      );
      return res;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Action Taken status filter-Action View Tracker Screen
export const getActionTrackerActionTakenStatusFilterList = createAsyncThunk(
  "getActionTrackerActionTakenStatusFilterList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/masterdata/action-status-list`
      );
      return res;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Action Taken status-popup submit call-action tracker view table screen
export const updateActionTakenStatusofActionTracker = createAsyncThunk(
  "updateActionTakenStatusofActionTracker",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/actiontracker/reco/action-taken`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get Attachment and comment table data
export const getAttachmentAndCommentTableData = createAsyncThunk(
  "getAttachmentAndCommentTableData",
  async (
    {
      recoId,
      pageNo,
      pageSize,
      sortBy,
      sortDirection,
      searchString,
      actionStatus,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/actiontracker/attachments/summary?recoId=${recoId}&pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchString=${searchString}&actionStatus=${actionStatus}`
      );
      return res;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//attachment and comment filter-action taken status
export const attachmentCommentfilterOfActionTakenStatus = createAsyncThunk(
  "attachmentCommentfilterOfActionTakenStatus",
  async (
    { recoId, pageNo, pageSize, sortBy, sortDirection, actionStatus },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.post(
        `/recon/api/v1/actiontracker/attachments/summary/filter
        `,
        {
          recoId,
          pageNo,
          pageSize,
          sortBy,
          sortDirection,
          actionStatus,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//for side panel-to get comments for comment drawer of attachment and coment
export const getAttachmentAndCommentForDrawer = createAsyncThunk(
  "getAttachmentAndCommentForDrawer",
  async ({ recoId, partyCode, summaryCode, pageNo }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/actiontracker/attachments?recoId=${recoId}&partyCode=${partyCode}&summaryCode=${summaryCode}&pageNo=${pageNo}`
      );
      return res;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//for side panel-comment and file attachment post call
export const updateCommentAndFileOfCommentDrawer = createAsyncThunk(
  "updateCommentAndFileOfCommentDrawer",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/recon/api/v1/actiontracker/attachments`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//for side panel-comment and file attachment post call
export const downloadFileFromCommentDrawer = createAsyncThunk(
  "downloadFileFromCommentDrawer",
  async ({ recoId, fileName }, { rejectWithValue }) => {
    try {
      axiosApi
        .get(
          `/recon/api/v1/actiontracker/company-document?recoId=${recoId}&documentName=${fileName}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//delete comment and file of comment and attachment drawer delete call
export const deleteCommentsAndFilesOfCommentDrawer = createAsyncThunk(
  "deleteCommentsAndFilesOfCommentDrawer",
  async (
    { recoId, partyCode, summaryCode, commentId },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.delete(
        `/recon/api/v1/actiontracker/attachments`,
        { data: { recoId, partyCode, summaryCode, commentId } }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download file-view action tracker table-under more action
export const downloadFileViewActionTrackerTable = createAsyncThunk(
  "downloadFileViewActionTrackerTable",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      axiosApi
        .get(`/recon/api/v1/actiontracker/summary-report?recoId=${recoId}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Add remark modal-for Action tracker screen
export const addRemarkforActionTracker = createAsyncThunk(
  "addRemarkforActionTracker",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/recon/api/v1/actiontracker/reco/add-remark`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Reco Repor status Data
export const getReportStatusData = createAsyncThunk(
  "getReportStatusData",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconreport/api/v1/largerecoreport?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Generate Reco Report
export const generateRecoReport = createAsyncThunk(
  "getReportStatusData",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconreport/api/v1/generate/largerecoreport`,
        { recoId, partyId }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadGeneratedReport = createAsyncThunk(
  "downloadGeneratedReport",
  async ({ recoId, partyId, reportName }, { rejectWithValue }) => {
    try {
      axiosApi
        .get(
          `/reconreport/api/v1/download/largerecoreport?recoId=${recoId}&partyId=${partyId}&reportName=${reportName}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", reportName);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete status="Not Sent" reco
export const deleteReco = createAsyncThunk(
  "deleteReco",
  async ({ recoStatus }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.delete(
        `/reconinit/api/v1/recorecords/with-status?reconStatus=${recoStatus}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Rename Request Title
export const renameRequestTitle = createAsyncThunk(
  "renameRequestTitle",
  async ({ recoId, requestTitle }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v2/recorecords/${recoId}/update-reco`,
        { requestTitle }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get total amount for Match Unmatch screen
export const getTotalAmount = createAsyncThunk(
  "getTotalAmount",
  async (
    {
      recoId,
      partyId,
      source1Val,
      source2Val,
      searchString,
      code,
      statusCodes,
    },
    { rejectWithValue }
  ) => {
    try {
      if (searchString === undefined) {
        searchString = "";
      }
      const res = await axiosApi.post(
        `/recon/api/v1/recorecords/total-amount/by-filters`,
        {
          recoId,
          partyId,
          comment: "",
          companyDataIds: [],
          selectAll: true,
          source1: source1Val,
          source2: source2Val,
          searchString,
          code,
          statusCodes,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Get Reco Configuration Data
export const getConfigurationData = createAsyncThunk(
  "getConfigurationData",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v2/recorecords/${recoId}/reco-config`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//delete comment rom comment drawer of all party
export const deleteCommentsfromCommentDrawer = createAsyncThunk(
  "deleteCommentsfromCommentDrawer",
  async ({ recoId, partyId, commentId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.delete(
        `/reconinit/api/v1/recorecords/tracking/delete-comment`,
        { data: { recoId, partyId, commentId } }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Generate Summary Report
export const generateSummaryReport = createAsyncThunk(
  "generateSummaryReport",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconreport/api/v1/summary-report/generate`,
        { recoId }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Generate Detailed Report
export const generateDetailedReport = createAsyncThunk(
  "generateDetailedReport",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconreport/api/v1/detail-report/generate`,
        { recoId }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get summary report status
export const getSummaryReportStatus = createAsyncThunk(
  "getSummaryReportStatus",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconreport/api/v1/summary-report/status?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get summary report status
export const getDetailedReportStatus = createAsyncThunk(
  "getDetailedReportStatus",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconreport/api/v1/detail-report/status?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Assign/Update Action Owner modal-For action tracker screen
export const updateActionOwnerForActionTracker = createAsyncThunk(
  "updateActionOwnerForActionTracker",
  async (
    {
      comment,
      actionOwnerName,
      actionOwnerEmail,
      actionOwnerId,
      dataIds,
      recoId,
      selectAll,
      uncheckedDataIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        // `/recon/api/v1/actiontracker/action-owner`,
        `/recon/api/v1/actiontracker/reco/action-owner`,
        {
          comment,
          actionOwnerName,
          actionOwnerEmail,
          actionOwnerId,
          dataIds,
          recoId,
          selectAll,
          uncheckedDataIds,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download party document
export const downloadPartyDocument = createAsyncThunk(
  "downloadPartyDocument",
  async (
    { recoId, partyId, statementName, displayName },
    { rejectWithValue }
  ) => {
    try {
      axiosApi
        .get(
          `/reconinit/api/v1/recorecords/party/download-statement?recoId=${recoId}&partyId=${partyId}&statementName=${statementName}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", displayName);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get summary report status
export const getAllRemindersForReco = createAsyncThunk(
  "getAllRemindersForReco",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/tracking/${recoId}/reminders`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Create new Reminders
export const createNewReminder = createAsyncThunk(
  "createNewReminder",
  async ({ recon_id, createReminders }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v1/recorecords/tracking/${recon_id}/reminder`,
        { reminders: createReminders }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Update Reminders
export const updateReminder = createAsyncThunk(
  "updateReminder",
  async ({ recon_id, updateReminders }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/tracking/${recon_id}/reminder`,
        { reminders: updateReminders }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Start all Reminders
export const startAllScheduledReminders = createAsyncThunk(
  "startAllScheduledReminders",
  async ({ recon_id, ids }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v1/recorecords/tracking/${recon_id}/reminder/start-all`,
        { ids }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Stop all Reminders
export const stopAllScheduledReminders = createAsyncThunk(
  "stopAllScheduledReminders",
  async ({ recon_id, ids }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v1/recorecords/tracking/${recon_id}/reminder/stop-all`,
        { ids }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Confirmation Details
export const getAllConfirmationDetails = createAsyncThunk(
  "getAllConfirmationDetails",
  async ({ reconId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/partyconfmn/api/v2/recorecords/confirmation/details/loggedin-user?reconId=${reconId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Confirmation Details
export const getAllPartyConfirmationDetails = createAsyncThunk(
  "getAllPartyConfirmationDetails",
  async ({ token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `/partyconfmn/api/v2/recorecords/confirmation-details`,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get company bulk statements
export const getCompanyBulkStatements = createAsyncThunk(
  "getCompanyBulkStatements",
  async ({ recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/company-statements?recoId=${recoId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const requestStatementslice = createSlice({
  name: "requestStatementslice",
  initialState: state,
  extraReducers: {
    //track reconciliation table
    [getTableRecoData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_COMPANY";
    },
    [getTableRecoData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_COMPANY";
      state.companyData = action.payload;
    },
    [getTableRecoData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_COMPANY";
      state.error = action.payload;
    },
    //track reconciliation table- search function
    [getSearchData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEARCH_COMPANY";
    },
    [getSearchData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEARCH_COMPANY";
      state.companyData = action.payload;
    },
    [getSearchData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEARCH_COMPANY";
      state.error = action.payload;
    },
    // statistics data
    [getStatisticsData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATISTICS_DATA";
    },
    [getStatisticsData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATISTICS_DATA";
      state.statisticsData = action.payload;
    },
    [getStatisticsData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATISTICS_DATA";
      state.error = action.payload;
    },
    // all party table data
    [getAllPartiesTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_ALLPARTY";
    },
    [getAllPartiesTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_ALLPARTY";
      state.AllPartyData = action.payload;
    },
    [getAllPartiesTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_ALLPARTY";
      state.error = action.payload;
    },
    // all party table data
    [getAllPartiesTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_ALLPARTY";
    },
    [getAllPartiesTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_ALLPARTY";
      state.AllPartyData = action.payload;
    },
    [getAllPartiesTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_ALLPARTY";
      state.error = action.payload;
    },
    // mark as review done
    [markAsReviewDone.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MARK_AS_REVIEW_DONE";
    },
    [markAsReviewDone.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MARK_AS_REVIEW_DONE";
    },
    [markAsReviewDone.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MARK_AS_REVIEW_DONE";
      state.error = action.payload;
    },
    //Reconciliation Table Data
    [getReconciliationTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_RECONCILIATION";
    },
    [getReconciliationTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_RECONCILIATION";
      state.ReconciliationData = action.payload;
      state.recoPendingCount = action.payload.totalItems;
    },
    [getReconciliationTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_RECONCILIATION";
      state.error = action.payload;
    },
    //Review Table Data
    [getReviewPendingTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_REVIEW";
    },
    [getReviewPendingTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_REVIEW";
      state.ReviewData = action.payload;
    },
    [getReviewPendingTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_REVIEW";
      state.error = action.payload;
    },
    //Party Sign Off Table Data
    [getSignOffTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_PARTYSIGNOFF";
    },
    [getSignOffTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_PARTYSIGNOFF";
      state.partySignOffData = action.payload;
    },
    [getSignOffTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_PARTYSIGNOFF";
      state.error = action.payload;
    },
    //Action Tracker Table Data
    [getActionTrackerTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_ACTIONTRACKER";
    },
    [getActionTrackerTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_ACTIONTRACKER";
      state.actionTrackerData = action.payload;
    },
    [getActionTrackerTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_ACTIONTRACKER";
      state.error = action.payload;
    },
    // download sumaary
    [getdownloadSummary.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_SUMMARY";
    },
    [getdownloadSummary.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_SUMMARY";
      state.reportsummary = action.payload;
    },
    [getdownloadSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_SUMMARY";
      state.error = action.payload;
    },
    // download Report
    [getdownloadReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_SUMMARY";
    },
    [getdownloadReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_SUMMARY";
      state.report = action.payload;
    },
    [getdownloadReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_SUMMARY";
      state.error = action.payload;
    },
    //close Request
    [closeRequest.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CLOSE_REQUEST";
    },
    [closeRequest.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CLOSE_REQUEST";
    },
    [closeRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CLOSE_REQUEST";
      state.error = action.payload;
    },
    //Reopen Request
    [reOpenRequest.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REOPEN_REQUEST";
    },
    [reOpenRequest.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REOPEN_REQUEST";
    },
    [reOpenRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REOPEN_REQUEST";
      state.error = action.payload;
    },
    //Start Reminder
    [startReminder.pending]: (state, action) => {
      state.status = "loading";
      state.type = "START_REMINDER";
    },
    [startReminder.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "START_REMINDER";
    },
    [startReminder.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "START_REMINDER";
      state.error = action.payload;
    },
    //Stop Reminder
    [stopReminder.pending]: (state, action) => {
      state.status = "loading";
      state.type = "STOP_REMINDER";
    },
    [stopReminder.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "STOP_REMINDER";
    },
    [stopReminder.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "STOP_REMINDER";
      state.error = action.payload;
    },
    // Dropdown list ContactPersonType-Modify Configuration
    [getContactPersonType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DROPDOWNLIST_TYPE";
    },
    [getContactPersonType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DROPDOWNLIST_TYPE";
      state.contactPersonType = action.payload;
    },
    [getContactPersonType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DROPDOWNLIST_TYPE";
      state.error = action.payload;
    },
    // Dropdown list EmailAttachment-Modify Configuration
    [getEmailAttachment.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_EMAIL_ATTACHMENT";
    },
    [getEmailAttachment.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_EMAIL_ATTACHMENT";
      state.emailAttachment = action.payload;
    },
    [getEmailAttachment.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_EMAIL_ATTACHMENT";
      state.error = action.payload;
    },
    // Dropdown list ReminderTemplate-Modify Configuration
    [getReminderTemplate.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REMINDER_TEMPLATE";
    },
    [getReminderTemplate.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REMINDER_TEMPLATE";
      state.reminderTemplate = action.payload;
    },
    [getReminderTemplate.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REMINDER_TEMPLATE";
      state.error = action.payload;
    },
    //submit data -Modify Configuration
    [sendModifyConfigurationData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_MODIFY_DATA";
    },
    [sendModifyConfigurationData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_MODIFY_DATA";
      state.modifyConfigurationData = action.payload;
    },
    [sendModifyConfigurationData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_MODIFY_DATA";
      state.error = action.payload;
    },
    //Attach  SignOff All Parties
    [attachSignOff.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ATTACH_SIGNOFF_DATA";
    },
    [attachSignOff.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ATTACH_SIGNOFF_DATA";
      state.signOff = action.payload;
    },
    [attachSignOff.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ATTACH_SIGNOFF_DATA";
      state.error = action.payload;
    },
    //Get File Needed
    [getFileNeeded.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_FILE_NEEDED";
    },
    [getFileNeeded.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_FILE_NEEDED";
    },
    [getFileNeeded.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_FILE_NEEDED";
      state.error = action.payload;
    },
    //Get Emails To and Cc
    [getEmails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_EMAILS";
    },
    [getEmails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_EMAILS";
    },
    [getEmails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_EMAILS";
      state.error = action.payload;
    },
    // request signoff
    [requestSignOff.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REQUEST_SIGNOFF";
    },
    [requestSignOff.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REQUEST_SIGNOFF";
    },
    [requestSignOff.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REQUEST_SIGNOFF";
      state.error = action.payload;
    },
    //Mark As High Priority
    [markAsHighPriority.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MARK_HIGH_PRIORITY";
    },
    [markAsHighPriority.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MARK_HIGH_PRIORITY";
    },
    [markAsHighPriority.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MARK_HIGH_PRIORITY";
      state.error = action.payload;
    },
    //Mark As Unmark/Remove Priority
    [markAsRemovePriority.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UNMARK/REMOVE_PRIORITY";
    },
    [markAsRemovePriority.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UNMARK/REMOVE_PRIORITY";
    },
    [markAsRemovePriority.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UNMARK/REMOVE_PRIORITY";
      state.error = action.payload;
    },
    // request signoff
    [requestSignOff.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REQUEST_SIGNOFF";
    },
    [requestSignOff.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REQUEST_SIGNOFF";
    },
    [requestSignOff.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REQUEST_SIGNOFF";
      state.error = action.payload;
    },
    //Mark As High Priority
    [markAsHighPriority.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MARK_HIGH_PRIORITY";
    },
    [markAsHighPriority.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MARK_HIGH_PRIORITY";
    },
    [markAsHighPriority.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MARK_HIGH_PRIORITY";
      state.error = action.payload;
    },
    //Mark As Unmark/Remove Priority
    [markAsRemovePriority.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UNMARK/REMOVE_PRIORITY";
    },
    [markAsRemovePriority.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UNMARK/REMOVE_PRIORITY";
    },
    [markAsRemovePriority.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UNMARK/REMOVE_PRIORITY";
      state.error = action.payload;
    },
    // reject reco
    [rejectReco.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MARK_AS_REVIEW_DONE";
    },
    [rejectReco.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MARK_AS_REVIEW_DONE";
    },
    [rejectReco.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MARK_AS_REVIEW_DONE";
      state.error = action.payload;
    },
    //Reject Statement
    [rejectStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REJECT_SIGNOFF";
    },
    [rejectStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REJECT_SIGNOFF";
    },
    [rejectStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REJECT_SIGNOFF";
      state.error = action.payload;
    },
    //Send for reviewsend
    [sendForReview.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_FOR_REVIEW";
    },
    [sendForReview.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_FOR_REVIEW";
    },
    [sendForReview.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_FOR_REVIEW";
      state.error = action.payload;
    },
    //Send comment
    [sendComment.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_COMMENT";
    },
    [sendComment.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_COMMENT";
    },
    [sendComment.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_COMMENT";
      state.error = action.payload;
    },
    // Reminder Template Type
    [getReminderTemplateTypes.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REMINDER_TYPE";
    },
    [getReminderTemplateTypes.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REMINDER_TYPE";
      state.reminderTemplateType = action.payload;
    },
    [getReminderTemplateTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REMINDER_TYPE";
      state.error = action.payload;
    },
    // Template content Type
    [getTemplateContentsTypes.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_TEMPLATE_TYPE";
    },
    [getTemplateContentsTypes.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_TEMPLATE_TYPE";
      state.templateContentType = action.payload;
    },
    [getTemplateContentsTypes.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_TEMPLATE_TYPE";
      state.error = action.payload;
    },
    //Send Reminder
    [sendReminder.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_REMINDER";
    },
    [sendReminder.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_REMINDER";
    },
    [sendReminder.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_REMINDER";
      state.error = action.payload;
    },
    //Status action
    [statusActions.pending]: (state, action) => {
      state.status = "loading";
      state.type = "STATUS_ACTION";
    },
    [statusActions.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "STATUS_ACTION";
      state.recoStatus = action.payload;
    },
    [statusActions.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "STATUS_ACTION";
      state.error = action.payload;
    },
    //Party contacts
    [partyContacts.pending]: (state, action) => {
      state.status = "loading";
      state.type = "PARTY_CONTACTS";
    },
    [partyContacts.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "PARTY_CONTACTS";
      state.partyContacts = action.payload.contacts;
    },
    [partyContacts.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "PARTY_CONTACTS";
      state.error = action.payload;
    },
    //Add comment
    [addComment.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ADD_COMMENT";
    },
    [addComment.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ADD_COMMENT";
    },
    [addComment.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ADD_COMMENT";
      state.error = action.payload;
    },
    //Update contacts
    [updateContacts.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_CONTACTS";
    },
    [updateContacts.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_CONTACTS";
    },
    [updateContacts.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_CONTACTS";
      state.error = action.payload;
    },
    //Get All Comments
    [getAllComments.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_COMMENTS";
    },
    [getAllComments.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_COMMENTS";
      state.allComments = action.payload.allTrackingComment;
    },
    [getAllComments.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_COMMENTS";
      state.error = action.payload;
    },
    //Get Reco Summary
    [getRecoSummary.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_COMMENTS";
    },
    [getRecoSummary.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_COMMENTS";
      state.recoSummary = action.payload;
    },
    [getRecoSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_COMMENTS";
      state.error = action.payload;
    },
    //Get unmatched Data
    [getUnMatchedData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_MATCHED_DATA";
    },
    [getUnMatchedData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_MATCHED_DATA";
      // state.UnmatchedData = action.payload;
    },
    [getUnMatchedData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_MATCHED_DATA";
      state.error = action.payload;
    },
    //Get source One dropdown list
    [getSourceOneList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SOURCE1_LIST";
    },
    [getSourceOneList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SOURCE1_LIST";
    },
    [getSourceOneList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SOURCE1_LIST";
      state.error = action.payload;
    },
    //Get source tWO dropdown list
    [getSourceTwoList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SOURCE2_LIST";
    },
    [getSourceTwoList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SOURCE2_LIST";
    },
    [getSourceTwoList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SOURCE2_LIST";
      state.error = action.payload;
    },
    //Get Status dropdown list
    [getStatusUnmatchedList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATUS_LIST";
    },
    [getStatusUnmatchedList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATUS_LIST";
    },
    [getStatusUnmatchedList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATUS_LIST";
      state.error = action.payload;
    },
    //For Link Records-Unmatched Data Table
    [linkRecords.pending]: (state, action) => {
      state.status = "loading";
      state.type = "LINK_RECORDS";
    },
    [linkRecords.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "LINK_RECORDS";
    },
    [linkRecords.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "LINK_RECORDS";
      state.error = action.payload;
    },
    //For De-Link Records-matched Data Table
    [deLinkRecords.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DE_LINK_RECORDS";
    },
    [deLinkRecords.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DE_LINK_RECORDS";
    },
    [deLinkRecords.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DE_LINK_RECORDS";
      state.error = action.payload;
    },
    //addRemark
    [addRemark.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ADD_REMARK";
    },
    [addRemark.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ADD_REMARK";
    },
    [addRemark.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ADD_REMARK";
      state.error = action.payload;
    },
    //Update Status Modal
    [updateStatus.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_STATUS";
    },
    [updateStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_STATUS";
    },
    [updateStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_STATUS";
      state.error = action.payload;
    },
    // Reco Summary Query Details
    [getQueryDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "QUERY_DETAILS";
    },
    [getQueryDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "QUERY_DETAILS";
      state.queryData = action.payload;
    },
    [getQueryDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "QUERY_DETAILS";
      state.error = action.payload;
    },
    // Respond to QQuery
    [respondQuery.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RESPOND_QUERY";
    },
    [respondQuery.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RESPOND_QUERY";
    },
    [respondQuery.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RESPOND_QUERY";
      state.error = action.payload;
    },
    //Accept Recommendation
    [acceptRecommendation.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ACCEPT_RECOMMENDATION";
    },
    [acceptRecommendation.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ACCEPT_RECOMMENDATION";
    },
    [acceptRecommendation.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ACCEPT_RECOMMENDATION";
      state.error = action.payload;
    },
    //Reject Recommendation
    [rejectRecommendation.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REJECT_RECOMMENDATION";
    },
    [rejectRecommendation.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REJECT_RECOMMENDATION";
    },
    [rejectRecommendation.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REJECT_RECOMMENDATION";
      state.error = action.payload;
    },
    //Update Action Taken
    [updateActionTaken.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_ACTION_TAKEN";
    },
    [updateActionTaken.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_ACTION_TAKEN";
    },
    [updateActionTaken.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_ACTION_TAKEN";
      state.error = action.payload;
    },
    //Update Action Taken Modal-Dropdown of Action Taken
    [getActionTakenList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ACTION_TAKEN_LIST";
    },
    [getActionTakenList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ACTION_TAKEN_LIST";
    },
    [getActionTakenList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ACTION_TAKEN_LIST";
      state.error = action.payload;
    },
    //Assign/Update Action owner
    [updateActionOwner.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_ACTION_OWNER";
    },
    [updateActionOwner.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_ACTION_OWNER";
    },
    [updateActionOwner.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_ACTION_OWNER";
      state.error = action.payload;
    },
    //Get Confirmation token for Mark As Respond Action
    [getRespondToken.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_MODIFY_DATA";
    },
    [getRespondToken.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_MODIFY_DATA";
    },
    [getRespondToken.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_MODIFY_DATA";
      state.error = action.payload;
    },
    //Update Status List
    [getUpdateStatusList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_STATUS_LIST";
    },
    [getUpdateStatusList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_STATUS_LIST";
    },
    [getUpdateStatusList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_STATUS_LIST";
      state.error = action.payload;
    },
    //Update Status List
    [getLinkList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "LINK_STATUS_LIST";
    },
    [getLinkList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "LINK_STATUS_LIST";
    },
    [getLinkList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "LINK_STATUS_LIST";
      state.error = action.payload;
    },
    //View Remark List
    [getViewRemarkData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VIEW_REMARK_LIST";
    },
    [getViewRemarkData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VIEW_REMARK_LIST";
      state.viewremarksdata = action.payload;
    },
    [getViewRemarkData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VIEW_REMARK_LIST";
      state.error = action.payload;
    },
    //Get Count Of Table Records
    [getCountOfTableRecords.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RECORD_COUNT";
    },
    [getCountOfTableRecords.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RECORD_COUNT";
      state.countOfRecord = action.payload;
    },
    [getCountOfTableRecords.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RECORD_COUNT";
      state.error = action.payload;
    },
    // Download document
    [downloadDocument.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadDocument.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadDocument.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_REPORT";
      state.error = action.payload;
    },
    // To get Updated Status List of unmatched screen
    [getUpdatedStatusList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_UPDATED_STATUS_LIST";
    },
    [getUpdatedStatusList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_UPDATED_STATUS_LIST";
      state.UnmatchedData = action.payload;
    },
    [getUpdatedStatusList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_UPDATED_STATUS_LIST";
      state.error = action.payload;
    },
    // To get Updated Status List of Matched screen
    [getMatchedList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_UPDATED_STATUS_LIST";
    },
    [getMatchedList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_UPDATED_STATUS_LIST";
      state.MatchedData = action.payload;
    },
    [getMatchedList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_UPDATED_STATUS_LIST";
      state.error = action.payload;
    },
    // Get Reco Record By Id
    [getRecoRecordById.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_RECO_RECORD_BY_ID";
    },
    [getRecoRecordById.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_RECO_RECORD_BY_ID";
    },
    [getRecoRecordById.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_RECO_RECORD_BY_ID";
      state.error = action.payload;
    },
    // Get Records for view action tracker table
    [getRecordsToViewActionTrackerRecords.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ACTION_TRACKER_VIEW_RECORDS";
    },
    [getRecordsToViewActionTrackerRecords.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ACTION_TRACKER_VIEW_RECORDS";
      state.viewActionTrackerData = action.payload;
    },
    [getRecordsToViewActionTrackerRecords.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ACTION_TRACKER_VIEW_RECORDS";
      state.error = action.payload;
    },
    //to get options of dropdown options for Action Owner List
    [getActionTrackerActionOwnerStatusList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ACTION_TRACKER_ACTION_OWNER_STATUS_LIST";
    },
    [getActionTrackerActionOwnerStatusList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ACTION_TRACKER_ACTION_OWNER_STATUS_LIST";
    },
    [getActionTrackerActionOwnerStatusList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ACTION_TRACKER_ACTION_OWNER_STATUS_LIST";
      state.error = action.payload;
    },
    //to get options of dropdown options for Action Taken Status Filter List
    [getActionTrackerActionTakenStatusFilterList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ACTION_TRACKER_ACTION_TAKEN_FILTER_STATUS_LIST";
    },
    [getActionTrackerActionTakenStatusFilterList.fulfilled]: (
      state,
      action
    ) => {
      state.status = "succeed";
      state.type = "GET_ACTION_TRACKER_ACTION_TAKEN_FILTER_STATUS_LIST";
    },
    [getActionTrackerActionTakenStatusFilterList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ACTION_TRACKER_ACTION_TAKEN_FILTER_STATUS_LIST";
      state.error = action.payload;
    },
    //to get options of dropdown options for Reconciliation Status Filter List
    [getActionTrackerReconciliationStatusFilterList.pending]: (
      state,
      action
    ) => {
      state.status = "loading";
      state.type = "GET_RECONCILIATION_FILTER_STATUS_LIST";
    },
    [getActionTrackerReconciliationStatusFilterList.fulfilled]: (
      state,
      action
    ) => {
      state.status = "succeed";
      state.type = "GET_RECONCILIATION_FILTER_STATUS_LIST";
    },
    [getActionTrackerReconciliationStatusFilterList.rejected]: (
      state,
      action
    ) => {
      state.status = "failed";
      state.type = "GET_RECONCILIATION_FILTER_STATUS_LIST";
      state.error = action.payload;
    },
    // Submit action taken status of action tracker screen
    [updateActionTakenStatusofActionTracker.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_ACTION_TAKEN_STATUS_ACTIONTRACKER";
    },
    [updateActionTakenStatusofActionTracker.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_ACTION_TAKEN_STATUS_ACTIONTRACKER";
    },
    [updateActionTakenStatusofActionTracker.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_ACTION_TAKEN_STATUS_ACTIONTRACKER";
      state.error = action.payload;
    },
    //to get Attachment and comment table data
    [getAttachmentAndCommentTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ATTACHMENT&COMMENT_DATA";
    },
    [getAttachmentAndCommentTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ATTACHMENT&COMMENT_DATA";
      state.attchementCommentTableData = action.payload.data;
    },
    [getAttachmentAndCommentTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ATTACHMENT&COMMENT_DATA";
      state.error = action.payload;
    },
    //update action tracker view click table data
    // [updateViewActionTrackerTableData.pending]: (state, action) => {
    //   state.status = "loading";
    //   state.type = "UPDATE_VIEW_ACTION_TRACKER_TABLE_DATA";
    // },
    // [updateViewActionTrackerTableData.fulfilled]: (state, action) => {
    //   state.status = "succeed";
    //   state.type = "UPDATE_VIEW_ACTION_TRACKER_TABLE_DATA";
    //   state.viewActionTrackerData = action.payload;
    // },
    // [updateViewActionTrackerTableData.rejected]: (state, action) => {
    //   state.status = "failed";
    //   state.type = "UPDATE_VIEW_ACTION_TRACKER_TABLE_DATA";
    //   state.error = action.payload;
    // },
    //attachment and comment action taken status filter-to update table
    [attachmentCommentfilterOfActionTakenStatus.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ATTACHMENTCOMMENT_FILTER_OF_ACTION_TAKEN_STATUS";
    },
    [attachmentCommentfilterOfActionTakenStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ATTACHMENTCOMMENT_FILTER_OF_ACTION_TAKEN_STATUS";
      state.attchementCommentTableData = action.payload;
    },
    [attachmentCommentfilterOfActionTakenStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ATTACHMENTCOMMENT_FILTER_OF_ACTION_TAKEN_STATUS";
      state.error = action.payload;
    },
    //attachment and comment drawer comments
    [getAttachmentAndCommentForDrawer.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ATTACHMENT_COMMENTS_FOR_SIDE_PANEL";
    },
    [getAttachmentAndCommentForDrawer.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ATTACHMENT_COMMENTS_FOR_SIDE_PANEL";
      state.attchementCommentForDrawerComments = action.payload.data;
    },
    [getAttachmentAndCommentForDrawer.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ATTACHMENT_COMMENTS_FOR_SIDE_PANEL";
      state.error = action.payload;
    },
    //attachment and comment drawer comments-upload file and comment
    [updateCommentAndFileOfCommentDrawer.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ATTACHMENT_COMMENTS_FILE_UPLOAD_FOR_SIDE_PANEL";
    },
    [updateCommentAndFileOfCommentDrawer.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ATTACHMENT_COMMENTS_FILE_UPLOAD_FOR_SIDE_PANEL";
    },
    [updateCommentAndFileOfCommentDrawer.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ATTACHMENT_COMMENTS_FILE_UPLOAD_FOR_SIDE_PANEL";
      state.error = action.payload;
    },
    //attachment and comment drawer comments-download file
    [downloadFileFromCommentDrawer.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ATTACHMENT_COMMENTS_DOWNLOAD_FILE_FOR_SIDE_PANEL";
    },
    [downloadFileFromCommentDrawer.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ATTACHMENT_COMMENTS_DOWNLOAD_FILE_FOR_SIDE_PANEL";
    },
    [downloadFileFromCommentDrawer.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ATTACHMENT_COMMENTS_DOWNLOAD_FILE_FOR_SIDE_PANEL";
      state.error = action.payload;
    },
    //delete comment and file form comment drawer
    [deleteCommentsAndFilesOfCommentDrawer.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DELETE_COMMENTS_FILE_FOR_SIDE_PANEL";
    },
    [deleteCommentsAndFilesOfCommentDrawer.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DELETE_COMMENTS_FILE_FOR_SIDE_PANEL";
    },
    [deleteCommentsAndFilesOfCommentDrawer.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DELETE_COMMENTS_FILE_FOR_SIDE_PANEL";
      state.error = action.payload;
    },
    //download file view action tracker table
    [downloadFileViewActionTrackerTable.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_FILE_MORE_ACTION";
    },
    [downloadFileViewActionTrackerTable.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_FILE_MORE_ACTION";
    },
    [downloadFileViewActionTrackerTable.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_FILE_MORE_ACTION";
      state.error = action.payload;
    },
    // Get Reco Repor status Data
    [getReportStatusData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REPORT_STATUS_DATA";
    },
    [getReportStatusData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REPORT_STATUS_DATA";
      state.reportStatusData = action.payload;
    },
    [getReportStatusData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REPORT_STATUS_DATA";
      state.error = action.payload;
    },
    // Generate Reco Report
    [generateRecoReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GENERATE_RECO_REPORT";
    },
    [generateRecoReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GENERATE_RECO_REPORT";
    },
    [generateRecoReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GENERATE_RECO_REPORT";
      state.error = action.payload;
    },
    //download Generated Report
    [downloadGeneratedReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_GENERATED_REPORT";
    },
    [downloadGeneratedReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_GENERATED_REPORT";
    },
    [downloadGeneratedReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_GENERATED_REPORT";
      state.error = action.payload;
    },
    [deleteReco.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DELETE_RECO";
    },
    [deleteReco.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DELETE_RECO";
    },
    [deleteReco.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DELETE_RECO";
      state.error = action.payload;
    },
    //Rename Request Title
    [renameRequestTitle.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RENAME_REQUEST_TITLE";
    },
    [renameRequestTitle.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RENAME_REQUEST_TITLE";
    },
    [renameRequestTitle.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RENAME_REQUEST_TITLE";
      state.error = action.payload;
    },
    //Get Total Amount
    [getTotalAmount.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_TOTAL_AMOUNT";
    },
    [getTotalAmount.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_TOTAL_AMOUNT";
      state.totalAmount = action.payload.totalAmountAsString;
      state.totalSum = action.payload.totalAmount;
    },
    [getTotalAmount.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_TOTAL_AMOUNT";
      state.error = action.payload;
    },
    //Get Reco Configuration Data
    [getConfigurationData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_MODIFY_DATA";
    },
    [getConfigurationData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_MODIFY_DATA";
      state.configData = action.payload;
    },
    [getConfigurationData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_MODIFY_DATA";
      state.error = action.payload;
    },

    //delete comment
    [deleteCommentsfromCommentDrawer.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DELETE_COMMENT";
    },
    [deleteCommentsfromCommentDrawer.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DELETE_COMMENT";
    },
    [deleteCommentsfromCommentDrawer.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DELETE_COMMENT";
      state.error = action.payload;
    },
    //Generate Summary Report
    [generateSummaryReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GENERATE_SUMMARY_REPORT";
    },
    [generateSummaryReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GENERATE_SUMMARY_REPORT";
    },
    [generateSummaryReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GENERATE_SUMMARY_REPORT";
      state.error = action.payload;
    },
    //Generate Detailed Report
    [generateDetailedReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GENERATE_DETAILED_REPORT";
    },
    [generateDetailedReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GENERATE_DETAILED_REPORT";
    },
    [generateDetailedReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GENERATE_DETAILED_REPORT";
      state.error = action.payload;
    },
    //Get Summary report status
    [getSummaryReportStatus.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SUMMARY_REPORT_STATUS";
    },
    [getSummaryReportStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SUMMARY_REPORT_STATUS";
      state.summaryReportStatus = action.payload.reportStatus;
    },
    [getSummaryReportStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SUMMARY_REPORT_STATUS";
      state.error = action.payload;
    },
    //Get Detail report status
    [getDetailedReportStatus.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DETAIL_REPORT_STATUS";
    },
    [getDetailedReportStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DETAIL_REPORT_STATUS";
      state.detailedReportStatus = action.payload.reportStatus;
    },
    [getDetailedReportStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DETAIL_REPORT_STATUS";
      state.error = action.payload;
    },
    //add Remark- for Action Tracker
    [addRemarkforActionTracker.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ADD_REMARK_ACTION_TRACKER";
    },
    [addRemarkforActionTracker.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ADD_REMARK_ACTION_TRACKER";
    },
    [addRemarkforActionTracker.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ADD_REMARK_ACTION_TRACKER";
      state.error = action.payload;
    },
    //Assign/Update Action owner
    [updateActionOwnerForActionTracker.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_ACTION_OWNER_FOR_ACTION_TRACKER";
    },
    [updateActionOwnerForActionTracker.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_ACTION_OWNER_FOR_ACTION_TRACKER";
    },
    [updateActionOwnerForActionTracker.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_ACTION_OWNER_FOR_ACTION_TRACKER";
      state.error = action.payload;
    },
    //Get All Reminders for Reco
    [getAllRemindersForReco.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_REMINDERS_FOR_RECO";
    },
    [getAllRemindersForReco.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_REMINDERS_FOR_RECO";
      state.reminderList = action.payload.data;
    },
    [getAllRemindersForReco.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_REMINDERS_FOR_RECO";
      state.error = action.payload;
    },
    //Create Reminders for Reco
    [createNewReminder.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CREATE_NEW_REMINDERS";
    },
    [createNewReminder.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CREATE_NEW_REMINDERS";
    },
    [createNewReminder.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CREATE_NEW_REMINDERS";
      state.error = action.payload;
    },
    //Update Reminders for Reco
    [updateReminder.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_REMINDERS";
    },
    [updateReminder.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_REMINDERS";
    },
    [updateReminder.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_REMINDERS";
      state.error = action.payload;
    },
    //Start Reminders for Reco
    [startAllScheduledReminders.pending]: (state, action) => {
      state.status = "loading";
      state.type = "START_ALL_SCHEDULED_REMINDERS";
    },
    [startAllScheduledReminders.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "START_ALL_SCHEDULED_REMINDERS";
    },
    [startAllScheduledReminders.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "START_ALL_SCHEDULED_REMINDERS";
      state.error = action.payload;
    },
    //Stop Reminders for Reco
    [stopAllScheduledReminders.pending]: (state, action) => {
      state.status = "loading";
      state.type = "STOP_ALL_SCHEDULED_REMINDERS";
    },
    [stopAllScheduledReminders.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "STOP_ALL_SCHEDULED_REMINDERS";
    },
    [stopAllScheduledReminders.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "STOP_ALL_SCHEDULED_REMINDERS";
      state.error = action.payload;
    },
    //Get all confirmation details
    [getAllConfirmationDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_CONFIRMATION_DETAILS";
    },
    [getAllConfirmationDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_CONFIRMATION_DETAILS";
      state.confirmationDetails = action.payload;
    },
    [getAllConfirmationDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_CONFIRMATION_DETAILS";
      state.error = action.payload;
    },
    [getAllPartyConfirmationDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_PARTY_CONFIRMATION_DETAILS";
    },
    [getAllPartyConfirmationDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_PARTY_CONFIRMATION_DETAILS";
      state.confirmationDetails = action.payload;
    },
    [getAllPartyConfirmationDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_PARTY_CONFIRMATION_DETAILS";
      state.error = action.payload;
    },
    [getCompanyBulkStatements.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_COMPANY_BULK_STATEMENT";
    },
    [getCompanyBulkStatements.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_COMPANY_BULK_STATEMENT";
    },
    [getCompanyBulkStatements.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_COMPANY_BULK_STATEMENT";
      state.error = action.payload;
    },
  },

  reducers: {
    setActiveValue(state, action) {
      state.activeValue = action.payload;
    },
    setRecoId(state, action) {
      state.recoId = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSort(state, action) {
      state.sort = action.payload;
    },
    setPartyIds(state, action) {
      state.partyIds = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setActionViewTrackerSelected(state, action) {
      state.actionViewTrackerSelected = action.payload;
    },
    setmodifyConfigData(state, action) {
      state.modifyConfigData = action.payload;
    },
    setRecoName(state, action) {
      state.recoName = action.payload;
    },
    setPartyName(state, action) {
      state.partyName = action.payload;
    },
    setRecoPendingSelected(state, action) {
      state.recopendingselected = action.payload;
    },
    setGotIt(state, action) {
      state.gotIt = action.payload;
    },
    setPartySignOffSelected(state, action) {
      state.partySignOffSelected = action.payload;
    },
    setReviewPendingSelected(state, action) {
      state.reviewPendingSelected = action.payload;
    },
    setAllComments(state, action) {
      state.allComments = action.payload;
    },
    setPartyInfo(state, action) {
      state.partyInfo = action.payload;
    },
    setPartyId(state, action) {
      state.partyId = action.payload;
    },
    setCode(state, action) {
      state.code = action.payload;
    },
    setTrackRecoPage(state, action) {
      state.trackRecoPage = action.payload;
    },
    setPartyCode(state, action) {
      state.partyCode = action.payload;
    },
    setPartyDataIds(state, action) {
      //for matched/unmatched Data screens
      state.partyDataIds = action.payload;
    },
    setCompanyDataIds(state, action) {
      //for matched/unmatched Data screens
      state.companyDataIds = action.payload;
    },
    setSumOfRecords(state, action) {
      state.sumofRecords = action.payload;
    },
    setModule(state, action) {
      state.module = action.payload;
    },
    setRequestType(state, action) {
      state.requestType = action.payload;
    },
    setRecoIdofRow(state, action) {
      state.recoIdofRow = action.payload;
    },
    setRefresh(state, action) {
      state.refresh = action.payload;
    },
    setRecoOnOffStatus(state, action) {
      state.recoOnOffStatus = action.payload;
    },
    setRecoFromTrackScreen(state, action) {
      state.recoFromTrackScreen = action.payload;
    },
    setActionTakenStatusForViewActionTracker(state, action) {
      state.actionTakenStatusforViewActionTracker = action.payload;
    },
    setActionTrackerPartyIds(state, action) {
      state.actionTrackerPartyId = action.payload;
    },
    setActionTrackerPartyCode(state, action) {
      state.actionTrackerPartyCode = action.payload;
    },
    setActionTrackerSummaryCode(state, action) {
      state.actionTrackerSummaryCode = action.payload;
    },
    setTabName(state, action) {
      state.tabName = action.payload;
    },
    setButtonName(state, action) {
      state.btnName = action.payload;
    },
    setSelectedInfo(state, action) {
      state.selectedInfo = action.payload;
    },
    setSetOfComments(state, action) {
      state.attchementCommentForDrawerComments = action.payload;
    },
    setIsAllDataIdsSelected(state, action) {
      state.isAllDataIdsSelected = action.payload;
    },
    setSelectedAmount(state, action) {
      state.selectedAmount = action.payload;
    },
    setUnselected(state, action) {
      state.uncheckedDataIds = action.payload;
    },
    setUnselectedAmount(state, action) {
      state.unselectedAmount = action.payload;
    },
    setIsSignoffCompleted(state, action) {
      state.isSignoffCompleted = action.payload;
    },
    setIsMiniSidebar(state, action) {
      state.isMiniSidebar = action.payload;
    },
    setRecoStatus(state, action) {
      state.recoStatus = action.payload;
    },
    setShowAll(state, action) {
      state.showAll = action.payload;
    },
    setOpenNonExcelPopup(state, action) {
      state.openNonExcel = action.payload;
    },
  },
});

export const {
  setLoading,
  setPartyDetails,
  setActiveValue,
  setSort,
  setPartyIds,
  setSelected,
  setRecoId,
  setmodifyConfigData,
  setRecoName,
  setPartyName,
  setRecoPendingSelected,
  setPartySignOffSelected,
  setGotIt,
  setReviewPendingSelected,
  setAllComments,
  setPartyInfo,
  setPartyId,
  setCode,
  setTrackRecoPage,
  setPartyCode,
  setPartyDataIds,
  setCompanyDataIds,
  setSumOfRecords,
  setModule,
  setRequestType,
  setRecoIdofRow,
  setRefresh,
  setRecoOnOffStatus,
  setRecoFromTrackScreen,
  setActionViewTrackerSelected,
  setActionTakenStatusForViewActionTracker,
  setActionTrackerPartyIds,
  setActionTrackerPartyCode,
  setActionTrackerSummaryCode,
  setTabName,
  setButtonName,
  setSelectedInfo,
  setAttachmentName,
  setSetOfComments,
  setIsAllDataIdsSelected,
  setSelectedAmount,
  setUnselected,
  setUnselectedAmount,
  setIsSignoffCompleted,
  setIsMiniSidebar,
  setRecoStatus,
  setShowAll,
  setOpenNonExcelPopup,
} = requestStatementslice.actions;
const { reducer } = requestStatementslice;

export default reducer;
