import { combineReducers, configureStore } from "@reduxjs/toolkit";
import masterDataReducer from "./masterdata/slice";
import newPartyReducer from "./newparty/slice";
import confirmationReducer from "./confirmation/slice";
import tokenReducer from "./token/slice";
import directreconciliationReducer from "./directreconciliation/slice";
import requestStatementReducer from "./requeststatement/slice";
import partyRecoReducer from "./partyrecosummary/slice";
import schedulerReducer from "./scheduler/slice";
import companysettingsReducer from "./companysettings/slice";
import settingsReducer from "./setting/slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import sftpReducer from "./sftp/slice";
// import session from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  stateReconciler: hardSet,
  // blacklist: ["confirmation"],
};

const reducer = combineReducers({
  masterData: masterDataReducer,
  newParty: newPartyReducer,
  confirmation: confirmationReducer,
  directreconciliation: directreconciliationReducer,
  trackreconciliation: requestStatementReducer,
  token: tokenReducer,
  partyrecosummary: partyRecoReducer,
  scheduler: schedulerReducer,
  settings: settingsReducer,
  companysettings: companysettingsReducer,
  sftp: sftpReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
