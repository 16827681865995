import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosApi from "../../helper/axiosApi";
import state from "./state";

// to save all configure screen data
export const configureAllData = createAsyncThunk(
  "configureAllData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v1/recorecords/configure`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// to save all configure screen data
export const configureAllData2 = createAsyncThunk(
  "configureAllData2",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v2/recorecords/configure`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get preview modal data
export const getModalData = createAsyncThunk(
  "getModalData",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/${id}/reco-config`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to send preview modal data to backend
export const sendModalData = createAsyncThunk(
  "sendModalData",
  async ({ recoIdForUploadLedger, flagg }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/notify?saveAsTemplate=${flagg}`,
        // `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/notify`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDataByCompany = createAsyncThunk(
  "getDataByCompany",
  async ({ page, limit, sortBy, searchString }, { rejectWithValue }) => {
    try {
      if (searchString === undefined) {
        searchString = "";
      }
      const res = await axiosApi.get(
        `/partymaster/api/v1/party/active/by-company?pageNo=${page}&pageSize=${limit}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&searchString=${searchString}`
      );
      // const res = await axiosApi.get(
      //   `partymaster/api/v1/party/by-company?pageNo=${page}&pageSize=${limit}${
      //     sortBy
      //       ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
      //       : ""
      //   }&searchString=${searchText}`
      // );

      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchCompany = createAsyncThunk(
  "searchCompany",
  async ({ searchString }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(`/partymaster/api/v1/party/search`, {
        searchString,
      });
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRequestType = createAsyncThunk(
  "getRequestType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconinit/api/v1/request-types`);
      return res.data.requestTypes;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatementType = createAsyncThunk(
  "getStatementType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconinit/api/v1/balances-statements`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatementTypeV2 = createAsyncThunk(
  "getStatementTypeV2",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconinit/api/v2/balances-statements`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBranchType = createAsyncThunk(
  "getBranchType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/company/api/v1/branches`);
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getContactPersonType = createAsyncThunk(
  "getContactPersonType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/company/api/v1/companyusers`);
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmailTemplate = createAsyncThunk(
  "getEmailTemplate",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/company/api/v1/companies/email-templates`
      );
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReminderTemplate = createAsyncThunk(
  "getReminderTemplate",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/company/api/v1/companies/reminder-templates`
      );
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmailAttachment = createAsyncThunk(
  "getEmailAttachment",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/company/api/v1/companies/email-attachment`
      );
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getImportStatementOptions = createAsyncThunk(
  "getImportStatementOptions",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/reconinit/api/v2/import-statement`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSenderEmail = createAsyncThunk(
  "getSenderEmail",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/company/api/v1/companies/authorized-emails`
      );
      return res.data.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload ledger/open Item File
export const uploadLedgerFile = createAsyncThunk(
  "uploadLedgerFile",
  async ({ recoIdForUploadLedger, formData }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company/map`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDateFormats = createAsyncThunk(
  "getDateFormats",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`/masterdata/api/v1/dateformats`);
      return res.data.dateformats;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateData = createAsyncThunk(
  "validateData",
  async (data, { rejectWithValue }) => {
    try {
      //
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/company/validate`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload closing balance
export const uploadClosingBalance = createAsyncThunk(
  "uploadClosingBalance",
  async ({ recoIdForUploadLedger, formData }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company/closingbalance/map`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateClosingBalanceData = createAsyncThunk(
  "validateClosingBalanceData",
  async (data, { rejectWithValue }) => {
    try {
      //
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/company/closingbalance/validate`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get saved configuration template
export const getConfigTemplate = createAsyncThunk(
  "getConfigTemplate",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v2/recorecords/recoconfig/configtemplate`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload ledger/open Item File
export const uploadPartyCodesFile = createAsyncThunk(
  "uploadPartyCodesFile",
  async ({ recoIdForUploadLedger, formData }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/partycode/map`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload ledger/open Item SFTP File
export const uploadSFTPFile = createAsyncThunk(
  "uploadSFTPFile",
  async ({ recoIdForUploadLedger, formData }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company-sftp/map`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload ledger/open Item SFTP File
export const uploadSFTPGRIRFile = createAsyncThunk(
  "uploadSFTPGRIRFile",
  async ({ recoIdForUploadLedger, formData }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company/otherdata/sftp-upload`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadPartyCodeTemplate = createAsyncThunk(
  "downloadPartyCodeTemplate",
  async (_, { rejectWithValue }) => {
    try {
      axiosApi
        .get(`/reconinit/api/v1/recorecords/party-code/template`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "PartyCodeTemplateV1.1.xlsx");
          document.body.appendChild(link);
          link.click();
          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateGRIR = createAsyncThunk(
  "validateGRIR",
  async (data, { rejectWithValue }) => {
    try {
      //
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/company/validate/otherdata`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const newPartySlice = createSlice({
  name: "newPartySlice",
  initialState: state,
  extraReducers: {
    [configureAllData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CONFIGURE_ALL_DATA";
    },
    [configureAllData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CONFIGURE_ALL_DATA";
      state.configureData = action.payload;
      state.recoId = action.payload.recoId;
    },
    [configureAllData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CONFIGURE_ALL_DATA";
      state.error = action.payload;
    },
    [configureAllData2.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CONFIGURE_ALL_DATA_2";
    },
    [configureAllData2.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CONFIGURE_ALL_DATA_2";
      state.configureData = action.payload;
      state.recoId = action.payload.recoId;
    },
    [configureAllData2.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CONFIGURE_ALL_DATA_2";
      state.error = action.payload;
    },
    [getModalData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_MODAL_DATA";
    },
    [getModalData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_MODAL_DATA";
      state.modalData = action.payload;
    },
    [getModalData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_MODAL_DATA";
      state.error = action.payload;
    },
    [sendModalData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_MODAL_DATA";
    },
    [sendModalData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_MODAL_DATA";
      state.sendModalData = action.payload;
    },
    [sendModalData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_MODAL_DATA";
      state.error = action.payload;
    },
    [getDataByCompany.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_COMPANY";
    },
    [getDataByCompany.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_COMPANY";
      state.companyData = action.payload;
    },
    [getDataByCompany.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_COMPANY";
      state.error = action.payload;
    },
    [searchCompany.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEARCH_COMPANY";
    },
    [searchCompany.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEARCH_COMPANY";
      state.companyData = action.payload;
    },
    [searchCompany.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEARCH_COMPANY";
      state.error = action.payload;
    },
    [getRequestType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REQUEST_TYPE";
    },
    [getRequestType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REQUEST_TYPE";
      state.requestType = action.payload;
    },
    [getRequestType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REQUEST_TYPE";
      state.error = action.payload;
    },
    [getStatementType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATEMENT_TYPE";
    },
    [getStatementType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATEMENT_TYPE";
      // state.statementType = action.payload;
    },
    [getStatementType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATEMENT_TYPE";
      state.error = action.payload;
    },
    [getStatementTypeV2.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_STATEMENT_TYPE_V2";
    },
    [getStatementTypeV2.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_STATEMENT_TYPE_V2";
      // state.statementType = action.payload;
    },
    [getStatementTypeV2.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_STATEMENT_TYPE_V2";
      state.error = action.payload;
    },
    [getBranchType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_BRANCH_TYPE";
    },
    [getBranchType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_BRANCH_TYPE";
      state.branchType = action.payload;
    },
    [getBranchType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_BRANCH_TYPE";
      state.error = action.payload;
    },
    [getContactPersonType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_CONTACT_PERSON_TYPE";
    },
    [getContactPersonType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_CONTACT_PERSON_TYPE";
      state.contactPersonType = action.payload;
    },
    [getContactPersonType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_CONTACT_PERSON_TYPE";
      state.error = action.payload;
    },
    [getEmailTemplate.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_EMAIL_TEMPLATE";
    },
    [getEmailTemplate.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_EMAIL_TEMPLATE";
      state.emailTemplate = action.payload;
    },
    [getEmailTemplate.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_EMAIL_TEMPLATE";
      state.error = action.payload;
    },
    [getReminderTemplate.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REMINDER_TEMPLATE";
    },
    [getReminderTemplate.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REMINDER_TEMPLATE";
      state.reminderTemplate = action.payload;
    },
    [getReminderTemplate.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REMINDER_TEMPLATE";
      state.error = action.payload;
    },
    [getEmailAttachment.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_EMAIL_ATTACHMENT";
    },
    [getEmailAttachment.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_EMAIL_ATTACHMENT";
      state.emailAttachment = action.payload;
    },
    [getEmailAttachment.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_EMAIL_ATTACHMENT";
      state.error = action.payload;
    },
    [getSenderEmail.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SENDER_EMAIL";
    },
    [getSenderEmail.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SENDER_EMAIL";
      state.senderEmail = action.payload;
    },
    [getSenderEmail.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SENDER_EMAIL";
      state.error = action.payload;
    },
    [uploadLedgerFile.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_LEDGER_FILE";
    },
    [uploadLedgerFile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_LEDGER_FILE";
      state.uploadFile = action.payload;
      state.companyStatementData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.statementId = action.payload?.statementId;
    },
    [uploadLedgerFile.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_LEDGER_FILE";
      state.error = action.payload;
    },
    [getDateFormats.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATE_FORMATS";
    },
    [getDateFormats.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATE_FORMATS";
      state.dateFormats = action.payload;
    },
    [getDateFormats.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATE_FORMATS";
      state.error = action.payload;
    },
    [validateData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_DATA";
    },
    [validateData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_DATA";
      state.companyStatementData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [validateData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_DATA";
      state.error = action.payload;
    },
    [uploadClosingBalance.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_CLOSING_BALANCE";
    },
    [uploadClosingBalance.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_CLOSING_BALANCE";
      state.uploadFile = action.payload;
      state.companyStatementData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.statementId = action.payload?.statementId;
    },
    [uploadClosingBalance.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_CLOSING_BALANCE";
      state.error = action.payload;
    },
    [validateClosingBalanceData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_CLOSING_BALANCE_DATA";
    },
    [validateClosingBalanceData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_CLOSING_BALANCE_DATA";
      state.companyStatementData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [validateClosingBalanceData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_CLOSING_BALANCE_DATA";
      state.error = action.payload;
    },
    [getConfigTemplate.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_CONFIG_TEMPLATE";
    },
    [getConfigTemplate.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_CONFIG_TEMPLATE";
      state.configData = action.payload;
    },
    [getConfigTemplate.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_CONFIG_TEMPLATE";
      state.error = action.payload;
    },
    [uploadPartyCodesFile.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_PARTY_CODES_FILE";
    },
    [uploadPartyCodesFile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_PARTY_CODES_FILE";
      state.uploadFile = action.payload;
      state.companyStatementData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.statementId = action.payload?.statementId;
    },
    [uploadPartyCodesFile.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_PARTY_CODES_FILE";
      state.error = action.payload;
    },
    [uploadSFTPFile.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_SFTP_FILE";
    },
    [uploadSFTPFile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_SFTP_FILE";
      state.uploadFile = action.payload;
      state.companyStatementData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.statementId = action.payload?.statementId;
    },
    [uploadSFTPFile.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_SFTP_FILE";
      state.error = action.payload;
    },
    [uploadSFTPGRIRFile.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_SFTP_GRIR_FILE";
    },
    [uploadSFTPGRIRFile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_SFTP_GRIR_FILE";
      state.uploadFile = action.payload;
      state.companyStatementGRIR = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.statementIdGrir = action.payload?.statementId;
    },
    [uploadSFTPGRIRFile.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_SFTP_GRIR_FILE";
      state.error = action.payload;
    },
    [downloadPartyCodeTemplate.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_PARTY_CODE_TEMPLATE";
    },
    [downloadPartyCodeTemplate.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_PARTY_CODE_TEMPLATE";
    },
    [downloadPartyCodeTemplate.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_PARTY_CODE_TEMPLATE";
      state.error = action.payload;
    },
    [getImportStatementOptions.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_IMPORT_STATEMENT_OPTIONS";
    },
    [getImportStatementOptions.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_IMPORT_STATEMENT_OPTIONS";
    },
    [getImportStatementOptions.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_IMPORT_STATEMENT_OPTIONS";
      state.error = action.payload;
    },
    [validateGRIR.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_GRIR";
    },
    [validateGRIR.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_GRIR";
      state.companyStatementGRIR = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [validateGRIR.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_GRIR";
      state.error = action.payload;
    },
  },
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setBack(state, action) {
      state.activeTab = state.activeTab - 1;
    },
    setPartyIds(state, action) {
      state.partyIds = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setConfigData(state, action) {
      state.configData = action.payload;
    },
    setOpenGood(state, action) {
      state.openGood = action.payload;
    },
    setSort(state, action) {
      state.sort = action.payload;
    },
    setIsAllItemSelected(state, action) {
      state.isAllItemSelected = action.payload;
    },
    setSearchStringText(state, action) {
      state.searchText = action.payload;
    },
    setConfigureInfo(state, action) {
      state.configureInfo = action.payload;
    },
    setExistingRecoId(state, action) {
      state.existingRecoId = action.payload;
    },
    setRecoId(state, action) {
      state.recoId = action.payload;
    },
    setShareOption(state, action) {
      state.shareOption = action.payload;
    },
    setStatementIdGrir(state, action) {
      state.statementIdGrir = action.payload;
    },
  },
});

export const {
  setActiveTab,
  setPartyIds,
  setBack,
  setSelected,
  setConfigData,
  setOpenGood,
  setSort,
  setIsAllItemSelected,
  setSearchStringText,
  setConfigureInfo,
  setExistingRecoId,
  setRecoId,
  setShareOption,
  setStatementIdGrir,
} = newPartySlice.actions;

const { reducer } = newPartySlice;

export default reducer;
